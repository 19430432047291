import {createStore} from 'vuex'
import global from './modules/global'
import user from './modules/user'
import wallet from './modules/wallet'
import counter from './modules/counter.js'
import exchange from './modules/exchange.js'
import order from './modules/order.js'

export default createStore({
  modules: {
    global,
    user,
    wallet,
    counter,
    exchange,
    order
  },
  strict: process.env.NODE_ENV !== 'production'
})
