import request from '@/utils/request';
export default {
  verifyMobile(country_code, mobile) {
    //获取短信验证码
    return new Promise((resolve, reject) => {
      const url = '/verify/mobile';
      request.post(url, {
        country_code,
        mobile
      }).then((res) => {
        resolve(res)
      }).catch((error) => {
        reject(error.response.data);
      })
    })
  },
  verifyEmail(email) {
    //邮件验证码
    return new Promise((resolve, reject) => {
      const url = '/verify/email'
      request.post(url, {
        email
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err.response.data)
      })
    })
  },
  //检查用户是否完成验证
  verification() {
    return new Promise((resolve, reject) => {
      // const url = '/verification';旧版
      const url = '/authentication/getAuthRecord';
      request.get(url, {}).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response.data);
      });
    });
  },
  //选择验证类型
  verificationType(id, type, country_code) {
    return new Promise((resolve, reject) => {
      const url = '/verification/type';
      request.post(url, {
        id,
        type,  //证件类型     大陆身份证：IdentityCard   护照：Passport
        country_code  //国家代码
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response.data);
      });
    });
  },
  //上传身份验证图片接口
  verificationUploadImage() {
    return process.env.VUE_APP_API_BASE + '/pc/verification/upload-image'; //正面照：Positive；反面照：Reverse；手持照：Handheld
  }
}