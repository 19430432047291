import api from '@/api/api.js'
import { getUsdtUsdRate } from '@/api/sys'

const state = {
  config: {},
  coins: [],
  counterTypes: {},
  priceModes: {},
  paymentTypes: {},
  tradeType: 'OnlineSell',
  symbol: 'BTC',
  exec_symbol: '',
  manual_coins: '',
  // usdt转usd汇率
  usdtUsdRate: null,
  // 忘记密码
  showForgotPassword: false,
  // 登录
  showLoginPopup: false,
  // 登录窗口是否可以关闭
  showLoginPopupType: null
}

const mutations = {
  // 设置配置
  setConfig(state, config) {
    state.config = config
  },
  // 设置币种
  setCoins(state, coins) {
    state.coins = coins
  },
  // 设置柜台类型
  setCounterTypes(state, counterTypes) {
    state.counterTypes = counterTypes
  },
  setPriceModes(state, priceModes) {
    state.priceModes = priceModes
  },
  // 设置支付类型
  setPaymentTypes(state, paymentTypes) {
    state.paymentTypes = paymentTypes
  },
  // 初始化配置
  initCounterConfig(state) {
    let symbol = localStorage.getItem('SYMBOL')
    let tradeType = localStorage.getItem('TRADE_TYPE')
    state.symbol = symbol ? symbol : 'BTC'
    state.tradeType = tradeType ? tradeType : 'OnlineSell'
  },
  // 设置symbol
  setSymbol(state, symbol) {
    state.symbol = symbol
    localStorage.setItem('SYMBOL', symbol)
  },
  // 设置交易类型
  setTradeType(state, tradeType) {
    state.tradeType = tradeType
    localStorage.setItem('TRADE_TYPE', tradeType)
  },
  // 设置货币
  setCurrency(state, currency) {
    state.currency = currency
  },
  setExecSymbol(state, exec_symbol) {
    state.exec_symbol = exec_symbol
  },
  setManualCoins(state, manual_coins) {
    state.manual_coins = manual_coins
  },
  // 设置usdt转usd汇率
  setUsdtUsdRate(state, value) {
    state.usdtUsdRate = value
  },
  // 设置忘记密码弹窗
  setForgotPasswordPopup(state, value) {
    state.showForgotPassword = value
  },
  // 设置登录弹窗
  setShowLoginPopup(state, data) {
    state.showLoginPopup = data.value
    state.showLoginPopupType = data.type || null
  }
}

const actions = {
  // 获取配置
  getConfig({ commit }, coin) {
    api.configs(coin).then(res => {
      commit('setConfig', res)
      commit('setCoins', res.c2c_coins)
      commit('setCounterTypes', res.counter_types)
      commit('setPriceModes', res.price_modes)
      commit('setPaymentTypes', res.payment_providers)
      commit('setExecSymbol', res.exec_symbol)
      commit('setManualCoins', res.manual_coins)
    })
  },
  // 获取usdt转usd汇率
  getUsdtUsdRate({ commit }) {
    return new Promise((resolve, reject) => {
      getUsdtUsdRate()
        .then(res => {
          commit('setUsdtUsdRate', res.data || 0)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
