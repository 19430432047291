import request from '@/utils/request.js'

// 获取币种市场价
export function getCurrencyMarkePrice(coin){
  return request({
      method:'get',
      url: '/sys/market-price',
      params:{
        coin
      }
  })
}

// 获取法币汇率
export function getCurrencyExchangeRate(name){
    return request({
        method:'get',
        url: '/sys/exchange-rate',
        params:{
            name
        }
    })
}

