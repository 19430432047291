import request from '@/utils/request'
import request_used from '@/utils/request_used'

// 获取otoken
export function getOtoken() {
  return request.get('/sys/csrf-token')
}

// 获取用户信息
export function getProfile() {
  return request.get('/profile')
}

// 获取马甲列表
export function getVestList() {
  return request.post('/shadows')
}

// 马甲登录
export function vestLogin(id) {
  return request({
    method: 'post',
    url: '/shadow/login',
    data: {
      id
    }
  })
}

// 获取事件订阅数据
export function getEventSubscribeData() {
  return request.get('/event-subscribe')
}

// 更新事件订阅
export function updateEventSubscribe(channels) {
  return request({
    method: 'post',
    url: '/event-subscribe',
    data: {
      channels
    }
  })
}

// 修改简介
export function updateIntroduction(introduction) {
  return request({
    method: 'post',
    url: '/profile/introduction',
    data: {
      introduction
    }
  })
}

// 解除谷歌验证
export function googlefaUnbind(code) {
  return request({
    method: 'post',
    url: '/google2fa/unbind',
    data: {
      code
    }
  })
}

// 获取支付数据
export function getPaymentData() {
  return request.get('/payment/settings')
}

// 更新支付信息
export function updatePaymentInfo({ type, description, userName, qrcode, currency_code, is_show }) {
  return request_used({
    method: 'post',
    url: '/payment/save',
    data: {
      type,
      description,
      userName,
      currency_code,
      qrcode,
      is_show
    }
  })
}

// 更新支付信息银行卡
// export function updatePaymentInfoBank({
//   type,
//   realname,
//   bank_no,
//   bank_name,
//   bank_branch,
//   is_show
// }) {
//   return request({
//     method: 'post',
//     url: '/payment/save',
//     data: {
//       type,
//       realname,
//       bank_no,
//       bank_name,
//       bank_branch,
//       is_show
//     }
//   })
// }

// 获取订单
export function getOrderList(type, page, exports) {
  return request({
    method: 'get',
    url: '/order/list',
    params: {
      type,
      page,
      exports
    }
  })
}

// 导出订单数据
export function exportOrderData(type, page = 1, exports = true) {
  return request({
    method: 'get',
    url: '/order/list',
    params: {
      type,
      page,
      exports
    },
    responseType: 'blob'
  })
}

// 更新订单备注
export function updateOrderRemarks(id, remark) {
  return request({
    method: 'post',
    url: '/order/remark',
    data: {
      id,
      remark
    }
  })
}

// 获取广告列表
export function getAdList(type, status, page) {
  return request({
    method: 'get',
    url: '/counter/list',
    params: {
      type,
      status,
      page
    }
  })
}

// 关闭广告
export function closeAd(id) {
  return request({
    method: 'post',
    url: '/counter/closed',
    data: {
      id
    }
  })
}

// 开启广告
export function openAd(id, secondary_password, google2fa) {
  return request({
    method: 'post',
    url: '/counter/open',
    data: {
      id,
      secondary_password,
      google2fa
    }
  })
}

// 获取您信任的人
export function getTrustYouData(page) {
  return request({
    method: 'get',
    url: '/trust/my',
    params: {
      page
    }
  })
}

// 获取信任您的人
export function getYouTrustData(page) {
  return request({
    method: 'get',
    url: '/trust/by',
    params: {
      page
    }
  })
}

// 获取你屏蔽的人
export function getYouShieldData(page) {
  return request({
    method: 'get',
    url: '/ignore/my',
    params: {
      page
    }
  })
}

// 提交认证信息
//export function submitAuthInfo(realname, id_number) {
//  return request({
//    method: 'post',
//    //url: '/verification/basic-informationd',
//    url: '/authentication/basic/save',
//    data: {
//      realname,//真实姓名
//      id_number//证件号
//    }
//  })
//}

//更新企业地址信息
export function subKYCaddress(
  id,
  register_country_code,
  register_city,
  register_address,
  register_postal,
  business_country_code,
  business_city,
  business_address,
  business_postal
) {
  return request({
    method: 'post',
    url: '/kyc/enterprise/address',
    data: {
      id,
      register_country_code,
      register_city,
      register_address,
      register_postal,
      business_country_code,
      business_city,
      business_address,
      business_postal
    }
  })
}

//更新企业信息
export function subKYCacc(name, register_no, country_code, create_date, status) {
  return request({
    method: 'post',
    url: '/kyc/enterprise/account',
    data: {
      name,
      register_no,
      country_code,
      create_date,
      status
    }
  })
}

//查询企业信息
export function checkKYC() {
  return request({
    method: 'get',
    url: '/kyc/enterprise/detail',
    params: {}
  })
}

//提交企业信息
export function submitBusKYC(id) {
  return request({
    method: 'post',
    url: '/kyc/enterprise/submit',
    data: {
      id
    }
  })
}

//更新更多企业信息
export function submitKYCmore(id, legal_form, nature, abn, invest_legal_form, invest_country_code, is_contact, email) {
  return request({
    method: 'post',
    url: '/kyc/enterprise/more',
    data: {
      id,
      legal_form,
      nature,
      abn,
      invest_legal_form,
      invest_country_code,
      is_contact,
      email
    }
  })
}
export function submitKYCsource(id, capital_source, wealth_source, funds_source) {
  return request({
    method: 'post',
    url: '/kyc/enterprise/source',
    data: {
      id,
      capital_source,
      wealth_source,
      funds_source
    }
  })
}
export function submitKYCsupple(id, purpose, area_code, mobile, website) {
  return request({
    method: 'post',
    url: '/kyc/enterprise/supplement',
    data: {
      id,
      purpose,
      area_code,
      mobile,
      website
    }
  })
}

export function submitKYCRegisterCertificate(id, register_certificate) {
  return request({
    method: 'post',
    url: '/kyc/enterprise/upload',
    data: {
      id,
      register_certificate
    }
  })
}

export function submitKYCConstitution(id, constitution) {
  return request({
    method: 'post',
    url: '/kyc/enterprise/upload',
    data: {
      id,
      constitution
    }
  })
}

export function submitKYCRegisterDirectors(id, register_directors) {
  return request({
    method: 'post',
    url: '/kyc/enterprise/upload',
    data: {
      id,
      register_directors
    }
  })
}

export function submitKYCRegisterMembers(id, register_members) {
  return request({
    method: 'post',
    url: '/kyc/enterprise/upload',
    data: {
      id,
      register_members
    }
  })
}

export function submitKYCBusiness_status(id, business_status) {
  return request({
    method: 'post',
    url: '/kyc/enterprise/upload',
    data: {
      id,
      business_status
    }
  })
}

export function submitKYCAnti_terrorism(id, anti_terrorism) {
  return request({
    method: 'post',
    url: '/kyc/enterprise/upload',
    data: {
      id,
      anti_terrorism
    }
  })
}

export function submitKYCOutsource(id, outsource) {
  return request({
    method: 'post',
    url: '/kyc/enterprise/upload',
    data: {
      id,
      outsource
    }
  })
}

export function submitKYCBusinessAddress(id, business_address) {
  return request({
    method: 'post',
    url: '/kyc/enterprise/upload',
    data: {
      id,
      business_address
    }
  })
}

export function submitKYCRegisterMidMembers(id, register_mid_members) {
  return request({
    method: 'post',
    url: '/kyc/enterprise/upload',
    data: {
      id,
      register_mid_members
    }
  })
}

export function submitKYCInvestmentManagement(id, investment_management) {
  return request({
    method: 'post',
    url: '/kyc/enterprise/upload',
    data: {
      id,
      investment_management
    }
  })
}

export function submitKYCShareholder(id, shareholder) {
  return request({
    method: 'post',
    url: '/kyc/enterprise/upload',
    data: {
      id,
      shareholder
    }
  })
}

export function submitKYCProspectus(id, prospectus) {
  return request({
    method: 'post',
    url: '/kyc/enterprise/upload',
    data: {
      id,
      prospectus
    }
  })
}

export function submitKYCInvestmentManagerDirectors(id, investment_manager_directors) {
  return request({
    method: 'post',
    url: '/kyc/enterprise/upload',
    data: {
      id,
      investment_manager_directors
    }
  })
}

export function submitKYCSupplement(id, supplement) {
  return request({
    method: 'post',
    url: '/kyc/enterprise/upload',
    data: {
      id,
      supplement
    }
  })
}

export function submitKYCBusinessLicense(id, business_license) {
  return request({
    method: 'post',
    url: '/kyc/enterprise/upload',
    data: {
      id,
      business_license
    }
  })
}

export function submitKYCAuthorization(id, authorization) {
  return request({
    method: 'post',
    url: '/kyc/enterprise/upload',
    data: {
      id,
      authorization
    }
  })
}

export function submitKYCInvestmentManagerCertificate(id, investment_manager_certificate) {
  return request({
    method: 'post',
    url: '/kyc/enterprise/upload',
    data: {
      id,
      investment_manager_certificate
    }
  })
}

export function submitRelation(
  relation_id,
  type,
  name,
  mid_name,
  surname,
  birth_date,
  country_code,
  live_country_code,
  certificate_country_code,
  certificate_type,
  certificate_number,
  certificate_frontend,
  certificate_backend,
  persons,
  prove_file,
  prove_type,
  prove_date,
  prove_country_code,
  prove_province,
  prove_city,
  prove_address,
  prove_postal,
  bus_country_code,
  bus_province,
  bus_city,
  bus_address,
  bus_postal
) {
  return request({
    method: 'post',
    url: '/kyc/enterprise/relation',
    data: {
      relation_id,
      type,
      name,
      mid_name,
      surname,
      birth_date,
      country_code,
      live_country_code,
      certificate_country_code,
      certificate_type,
      certificate_number,
      certificate_frontend,
      certificate_backend,
      persons,
      prove_file,
      prove_type,
      prove_date,
      prove_country_code,
      prove_province,
      prove_city,
      prove_address,
      prove_postal,
      bus_country_code,
      bus_province,
      bus_city,
      bus_address,
      bus_postal
    }
  })
}

// 提交认证信息
export function submitAuthInfoAu(
  country_code,
  firstname,
  secondname,
  birthday,
  middlename,
  unitNumber,
  buildingNumber,
  postalCode,
  stateProvinceCode,
  streetName,
  streetType,
  suburb,
  idtype,
  id_number,
  state,
  address
) {
  return request({
    method: 'post',
    //url: '/verification/basic-informationd',
    url: '/authentication/basic/save?country_code=AU',
    data: {
      country_code: 'AU',
      firstname,
      secondname,
      birthday, //出生日期 2011-01-01模式
      middlename,
      unitNumber, //单元号
      buildingNumber, //楼栋编号或街道编号 - UI 上是街道编号
      postalCode, //邮政编码
      stateProvinceCode, //州缩写
      streetName, //街道名称
      streetType, //街道类型
      suburb, //郊区、城里、城外
      idtype, //默认 1只能是大陆身份证
      id_number, //身份证号
      state,
      address
    }
  })
}

export function submitAuthInfoCn(country_code = 'CN', realname, birthday, idtype, id_number, bank_no) {
  return request({
    method: 'post',
    //url: '/verification/basic-informationd',
    url: '/authentication/basic/save',
    data: {
      country_code,
      realname,
      birthday,
      idtype, //默认 1只能是大陆身份证
      id_number, //身份证号
      bank_no
    }
  })
}

export function submitAuthInfo(
  country_code,
  firstname,
  secondname,
  birthday,
  middlename,
  unitNumber,
  buildingNumber,
  postalCode,
  stateProvinceCode,
  streetName,
  streetType,
  suburb,
  idtype,
  id_number,
  state,
  address
) {
  return request({
    method: 'post',
    //url: '/verification/basic-informationd',
    url: '/authentication/basic/save',
    data: {
      country_code,
      firstname,
      secondname,
      birthday, //出生日期 2011-01-01模式
      middlename,
      unitNumber, //单元号
      buildingNumber, //楼栋编号或街道编号 - UI 上是街道编号
      postalCode, //邮政编码
      stateProvinceCode, //州缩写
      streetName, //街道名称
      streetType, //街道类型
      suburb, //郊区、城里、城外
      idtype, //默认 1只能是大陆身份证
      id_number, //身份证号
      state,
      address
    }
  })
}

// 提交认证照片
export function submitAuthImg(idtype, positive, reverse, selfie) {
  return request({
    method: 'post',
    //url: '/verification/basic-informationd',
    url: '/authentication/photo/save',
    data: {
      idtype,
      positive,
      reverse,
      selfie
    }
  })
}
