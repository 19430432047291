import request from '@/utils/request'
import request_java from '@/utils/request_java.js'

export default {
  //钱包详情
  balance() {
    return new Promise((resolve, reject) => {
      const url = '/wallet/balance'
      request
        .get(url, {})
        .then(response => {
          resolve(response.data) // 返回值为成功的提示信息文本。
        })
        .catch(error => {
          reject(error.response.data)
        })
    })
  },
  //钱包详情
  getMoney(coins) {
    return new Promise((resolve, reject) => {
      const url = '/package/info'
      request_java
        .post(url, {
          coins: coins.includes('null') ? 'DAEX,USDT' : coins
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response.data)
        })
    })
  },
  //取得充值接收地址
  address(coin) {
    return new Promise((resolve, reject) => {
      const url = '/wallet/address/' + coin
      request
        .get(url, {})
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response.data)
        })
    })
  },

  //取得充值记录
  depositRecords(coin, page) {
    return new Promise((resolve, reject) => {
      const url = '/wallet/records/deposit/' + coin
      request
        .get(url, {
          params: {
            page
          }
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response.data)
        })
    })
  },

  //提现记录列表
  withdrawRecords(coin, page) {
    return new Promise((resolve, reject) => {
      const url = '/wallet/records/withdraw/' + coin
      request
        .get(url, {
          params: {
            page
          }
        })
        .then(response => {
          // console.log('Success: ' + url, response.data);
          resolve(response.data)
        })
        .catch(error => {
          // console.log('Error: ' + url, error.response.data);
          reject(error.response.data)
        })
    })
  },

  //刷新存款，立即入账
  refresh(coin) {
    return new Promise((resolve, reject) => {
      const url = '/wallet/deposit/refresh'
      request
        .post(url, {
          coin
        })
        .then(response => {
          // console.log('Success: ' + url, response.data);
          resolve(response.data) // 返回值为成功的提示信息文本。
        })
        .catch(error => {
          // console.log('Error: ' + url, error.response.data);
          reject(error.response.data)
        })
    })
  },

  //转账入口
  send(coin, address, quantity, secondary_password, google2fa, remark) {
    return new Promise((resolve, reject) => {
      const url = '/wallet/send'
      request
        .post(url, {
          coin,
          address,
          quantity,
          secondary_password,
          google2fa,
          remark
        })
        .then(response => {
          // console.log('Success: ' + url, response.data);
          resolve(response.data) // 返回值为成功的提示信息文本。
        })
        .catch(error => {
          // console.log('Error: ' + url, error.response.data);
          reject(error.response.data)
        })
    })
  },

  //提现入口
  sendNetwork(coin, address, quantity, secondary_password, smsverify, google2fa, remark, block_type, memo) {
    return new Promise((resolve, reject) => {
      const url = '/wallet/send_network'
      request
        .post(url, {
          coin,
          address,
          quantity,
          secondary_password,
          smsverify,
          google2fa,
          remark,
          block_type,
          memo,
          emailverify: smsverify
        })
        .then(response => {
          // console.log('Success: ' + url, response.data);
          resolve(response.data) // 返回值为成功的提示信息文本。
        })
        .catch(error => {
          // console.log('Error: ' + url, error.response.data);
          reject(error.response.data)
        })
    })
  },
  //
  sendEmail() {
    return new Promise((resolve, reject) => {
      const url = 'user/send/email'
      request
        .post(url)
        .then(response => {
          // console.log('Success: ' + url, response.data);
          resolve(response.data) // 返回值为成功的提示信息文本。
        })
        .catch(error => {
          // console.log('Error: ' + url, error.response.data);
          reject(error.response.data)
        })
    })
  },
  sendMobile() {
    return new Promise((resolve, reject) => {
      const url = 'user/send/mobile'
      request
        .post(url)
        .then(response => {
          // console.log('Success: ' + url, response.data);
          resolve(response.data) // 返回值为成功的提示信息文本。
        })
        .catch(error => {
          // console.log('Error: ' + url, error.response.data);
          reject(error.response.data)
        })
    })
  },

  // 钱包收款二维码图片
  qrcode(coin, address) {
    return new Promise((resolve, reject) => {
      const url = 'wallet/qrcode/' + coin + '/' + address
      request
        .post(url, {})
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response.data)
        })
    })
  },

  //交易记录
  TransactionRecords(currency, page, interfaces) {
    return new Promise((resolve, reject) => {
      const url =
        '/wallet/records/transaction/' +
        currency +
        '/' +
        interfaces +
        '?language=' +
        window.localStorage.getItem('language')
      request
        .get(url, {
          params: {
            page
          }
        })
        .then(response => {
          // console.log('Success: ' + url, response.data);
          resolve(response.data)
        })
        .catch(error => {
          // console.log('Error: ' + url, error.response.data);
          reject(error.response.data)
        })
    })
  },

  //币种配置
  currencyConfig(coin) {
    return new Promise((resolve, reject) => {
      const url = '/sys/currency/config'
      request
        .get(url, {
          params: {
            coin
          }
        })
        .then(response => {
          // console.log('Success: ' + url, response.data);
          resolve(response.data) // 返回值为成功的提示信息文本。
        })
        .catch(error => {
          // console.log('Error: ' + url, error.response.data);
          reject(error.response.data)
        })
    })
  },
  // 提交提现
  send_network(parameter) {
    return new Promise((resolve, reject) => {
      const url = '/wallet/send_network'
      request
        .post(url, parameter)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response.data)
        })
    })
  },
  // 获取提现信息
  withdraw(coin) {
    return new Promise((resolve, reject) => {
      const url = '/wallet/withdraw/' + coin
      request
        .get(url, {
          params: {
            idtype: 1
          }
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response.data)
        })
    })
  },
  // 获取充值信息
  deposit(coin) {
    return new Promise((resolve, reject) => {
      const url = '/wallet/deposit/' + coin
      request
        .get(url, {
          params: {
            idtype: 1
          }
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response.data)
        })
    })
  }
}

// 充值ORN
export function rechargeORN({ coin, address, amount, secondary_password, google2fa }) {
  return request({
    method: 'post',
    url: '/wallet/deposit/recharge',
    data: {
      coin,
      address,
      amount,
      secondary_password,
      google2fa
    }
  })
}

// 钱包划转(转进得意宝)
export function transfer(params) {
  return new Promise((resolve, reject) => {
    const url = '/wallet/transfer'
    request
      .post(url, params)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

// 钱包总额
export function get_balance_total(params) {
  return new Promise((resolve, reject) => {
    const url = '/wallet/get_balance_total'
    request
      .get(url, {
        params: params
      })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}
