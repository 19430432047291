import { Message } from 'view-ui-plus'
import i18n from '@/lang/il8n'

const utils = {
  /**
   * usdt转美元
   * @param Number usdt_price
   * @param Number rate
   * @param Number fixedLength 保留小数位数
   */
  usdtToUsd(usdt_price, rate, fixedLength = 3) {
    let num = parseFloat(usdt_price) * rate
    num === 'NaN' && (num = 0)
    fixedLength && (num = num.toFixed(fixedLength))
    return num
  },
  /**
   * 价格单位转换
   * @param Number price 价格
   * @param Number fixedLength 保留小数位数
   */
  priceToShort(price, fixedLength = 2) {
    price = parseFloat(price)
    if (price >= 1000000) {
      price = price / 1000000
      price = price.toFixed(fixedLength)
      price += 'M'
    } else {
      return price.toFixed(fixedLength)
    }
    return price
  },
  /**
   * 保留有效数字
   * @param Number num 数字
   * @param Number fixedLength 保留小数位数
   */
  numberToFixed(num, fixedLength) {
    num = parseFloat(num)
    num === 'NaN' && (num = 0)
    fixedLength && (num = num.toFixed(fixedLength))
    return num
  },
  // 复制文本
  onCopy(text) {
    let input = document.createElement('input')
    input.value = text
    document.body.appendChild(input)
    input.select()
    document.execCommand('copy')
    document.body.removeChild(input)
    Message.success(i18n.t('Copy succeeded'))
  },
  // 数字补0
  addZero(i) {
    return i < 10 ? '0' + i : i + ''
  },
  // 倒计时
  countDown(endTime, beginTime = Date.now()) {
    var leftTime = parseInt((endTime - beginTime) / 1000)
    if (leftTime <= 0) {
      return ''
    }
    var d = parseInt(leftTime / (24 * 60 * 60))
    var h = parseInt((leftTime / (60 * 60)) % 24)
    var m = parseInt((leftTime / 60) % 60)
    var s = parseInt(leftTime % 60)
    h = utils.addZero(h)
    m = utils.addZero(m)
    s = utils.addZero(s)
    return `${d > 0 ? d + i18n.t('Day') + ' ' : ''}${h}:${m}:${s}`
  }
}

utils.getQueryString = getQueryString

/**
 * 获取url中的参数
 * @param url 完整链接
 * @param name 参数名
 */
export function getQueryString(url, name) {
  if (!url) {
    return null
  }
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  var r = url.split('?')[1]
  r = r ? r.match(reg) : null
  return r != null ? decodeURI(r[2]) : null
}

export default {
  install: function (that) {
    that.config.globalProperties.$utils = utils
  }
}
