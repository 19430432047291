import { getOtoken, getProfile } from '@/api/user.js'
import Profile from '@/api/profile.js'
import Verify from '@/api/verify.js'

const state = {
  otoken: null,
  isLogin: null,
  userInfo: null,
  userInfoId: null,
  auth: null,
  authStep: 0
}

const mutations = {
  // 设置otoken
  setOtoken(state, otoken) {
    state.otoken = otoken
  },
  // 设置登录状态
  setLoginStatus(state, loginStatus) {
    state.isLogin = loginStatus
  },
  // 设置用户信息
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo
    state.userInfoId = userInfo.id
    localStorage.setItem('userInfoId', userInfo.id)
  },
  // 移除登录信息
  removeLoginInfo(state) {
    state.otoken = null
    state.isLogin = false
    state.userInfo = null
  },
  // 设置认证信息
  setAuthInfo(state, auth) {
    state.auth = auth
  },
  // 设置认证步骤
  setAuthStep(state, authStep) {
    state.authStep = authStep
  }
}

const actions = {
  // 获取otoken
  getOtoken({ commit }) {
    return new Promise((resolve, reject) => {
      getOtoken()
        .then(res => {
          commit('setOtoken', res.data)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getProfile({ commit }) {
    // 获取用户信息
    return new Promise((resolve, reject) => {
      getProfile()
        .then(res => {
          let userInfo = res.data
          let loginStatus = userInfo.id ? true : false
          commit('setUserInfo', userInfo)
          commit('setLoginStatus', loginStatus)
          resolve(loginStatus)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // 获取登录状态
  getLoginStatus({ dispatch }) {
    return new Promise((resolve, reject) => {
      dispatch('getOtoken').then(() => {
        dispatch('getProfile')
          .then(loginStatus => {
            resolve(loginStatus)
          })
          .catch(err => {
            reject(err)
          })
      })
    })
  },
  // 登出
  logout({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      Profile.logout()
        .then(() => {
          dispatch('getOtoken')
          commit('removeLoginInfo')
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // 获取认证信息
  getAuthInfo({ commit }) {
    return new Promise((resolve, reject) => {
      Verify.verification()
        .then(res => {
          commit('setAuthInfo', res)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
