//引入加载字体
import {createI18n} from 'vue-i18n'
import en from './en.json'
import zh_CN from './zh-CN.json'
import zh_HK from './zh-HK.json'
import enLocale from 'view-ui-plus/src/locale/lang/en-US'
import zhLocale from 'view-ui-plus/src/locale/lang/zh-CN'
import zhTwLocale from 'view-ui-plus/src/locale/lang/zh-TW'

let messages = {
  en: { ...en, ...enLocale }, //英文
  'zh-CN': { ...zh_CN, ...zhLocale }, //中文
  'zh-HK': { ...zh_HK, ...zhTwLocale } //繁体
}

class CustomFormatter {
  constructor(options) {
    this.global_values = options
  }

  interpolate(message, values) {
    // 合并全局语言参数。
    let params = { ...this.global_values, ...values }

    // 顺序替换。
    for (let key in params) {
      message = message.replace(new RegExp(':' + key, 'g'), params[key])
    }

    return [message]
  }
}

function GetQueryString(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  var r = window.location.search.substr(1).match(reg) //获取url中"?"符后的字符串并正则匹配
  var context = ''
  if (r != null) context = decodeURIComponent(r[2])
  reg = null
  r = null
  return context == null || context == '' || context == 'undefined' ? '' : context
}

var query_lang = GetQueryString('language')
const queryLang =
  query_lang === 'en' || query_lang === 'en-US'
    ? 'en'
    : query_lang === 'zh-CN'
      ? 'zh-HK'
      : query_lang === 'zh-HK' || query_lang === 'zh-TW'
        ? 'zh-HK'
        : null

// 自动设置语言
const navLang = navigator.language
const localLang =
  navLang === 'en' || navLang === 'en-US'
    ? 'en'
    : navLang === 'zh-CN'
      ? 'zh-HK'
      : navLang === 'zh-HK' || navLang === 'zh-TW'
        ? 'zh-HK'
        : false
const lang = queryLang || window.localStorage.getItem('language') || localLang || 'en'
window.localStorage.setItem('language', lang)

// 多语言配置
const i18n = createI18n({
  locale: lang,
  globalInjection: true,
  formatter: new CustomFormatter({
    app_name: process.env.VUE_APP_APP_NAME
  }),
  allowComposition: true,
  missing: function(locale, key) {
    return key
  },
  silentTranslationWarn: true,
  messages
})
export default i18n