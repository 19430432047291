import Echo from 'laravel-echo';

function getQueryString(name){
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  var r = window.location.search.substr(1).match(reg);
  if (r != null) {
    localStorage.setItem('authorizationToken', r[2])
    return r[2]
  }
}

let authorizationToken = localStorage.getItem('authorizationToken');

if (!authorizationToken) {
  let au = getQueryString('token');
  if (au != null) {
    authorizationToken = au;
  } else {
    authorizationToken = '';
  }
}

let echo = new (function () {

  let _instance = null;

  this.isAval = function () {
    return window.io;
  };

  this.instance = function () {
    if ( ! _instance) {
      try {
        if (this.isAval()) {
          _instance = new Echo({
            broadcaster: 'socket.io',
            host: process.env.VUE_APP_ECHO_BASE,
            auth: { headers: { 'Authorization': 'Bearer ' + authorizationToken } }
          });
        }
      } catch (e) {
        // noop.
      }
    }
    return _instance;
  };

  this.reset = function () {
    _instance = null;
  };
});

export default echo;
