import {
  getUnreadMessages
} from "@/api/home.js";

const state = {
  unreadMessages: {}
}

const mutations = {
  setUnreadMessages(state, unreadMessages) {
    state.unreadMessages = unreadMessages
  }
}

const actions = {
  // 获取未读消息数
  getUnreadMessages({
    commit
  }) {
    getUnreadMessages().then(res => {
      commit('setUnreadMessages', res.data)
    });
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
