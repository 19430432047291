import wallet from '@/api/wallet.js'

const state = {
  balance: {},
}

const mutations = {
  setBalance(state, balance) {
    state.balance = balance
  },
  setSimpleCoinBalance(state, simpleCoinBalance) {
    state.balance[simpleCoinBalance.coin] = {
      balance_available: simpleCoinBalance.balance_available,
      balance_locked: simpleCoinBalance.balance_locked
    }
  }
}

const actions = {
  getBalanceFirst({commit}) {
    // 全部请求
    wallet.balance().then(res => {
      commit('setBalance', res)
    }).catch(() => {})
  },
  getBalance({commit},coins) {
    // 全部请求
    // wallet.balance().then(res => {
    //   commit('setBalance', res)
    // }).catch(err => {})
    wallet.getMoney(coins).then(res => {
      commit('setBalance', res.data)
    }).catch(() => {})
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
