import { createRouter,createWebHistory }  from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('@/views/layout/index.vue'),
    children: [
      {
        path: '/', // 一键购买
        name: 'quickBuy',
        component: () => import('@/views/quickBuy/index.vue')
      },
      {
        path: 'confirm', // 确认订单
        name: 'quickBuyConfirm',
        meta: {
          hideFooter: true
        },
        component: () => import('@/views/quickBuy/confirm.vue')
      },
      {
        path: 'about', // 关于我们
        name: 'quickBuyAbout',
        component: () => import('@/views/quickBuy/about.vue')
      },
      {
        path: 'search', // 订单查询
        name: 'quickBuySearch',
        meta: {
          hideFooter: true
        },
        component: () => import('@/views/quickBuy/search.vue')
      },
      {
        path: 'purchase-agreement', // 买币协议
        name: 'purchaseAgreement',
        component: () => import('@/views/purchase-agreement/index.vue')
      },
      {
        path: 'statement-terms', // 声明以及条款
        name: 'statementTerms',
        component: () => import('@/views/statement-terms/index.vue')
      },
      {
        path: 'privacy', // 隐私条款
        name: 'privacy',
        component: () => import('@/views/privacy/index.vue')
      },
      {
        path: 'terms-and-conditions', // 条款和规定
        name: 'termsAndConditions',
        component: () => import('@/views/terms-and-conditions/index.vue')
      },
      {
        path: 'aml', // 反洗黑钱政策
        name: 'aml',
        component: () => import('@/views/aml/index.vue')
      }
    ]
  },
  {
    path: '/404',
    name: 'notFound',
    component: () => import('@/views/error/404.vue')
  },
  {
    path: "/:pathMatch(.*)",
    redirect: '/404'
  }
]

export default createRouter({
  history:createWebHistory(),
  scrollBehavior: () => ({
    y: 0
  }),
  routes
})
