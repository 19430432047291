import request from '@/utils/request'

// 获取通知
export function getNotice(){
    return request.get('/sys/notice')
}

export function getTopNotice(){
   return request.get('/home/tickerAll_www')
}
// 获取轮播图
export function getCarousels(is_pc){
    return request({
        method:'get',
        url:'/sys/carousels',
        params:{
            is_pc
        }
    })
}
// 新币榜/涨幅榜/成交额榜
export function tickerAll(is_pc){
    return request({
        method:'get',
        url:'/home/tickerAll_www',
        // url:'https://api.daexglobal.com/pc/home/tickerAll_www',
        params:{
            is_pc
        }
    })
}

// 获取弹窗消息
export function getAlertMsg(){
    return request({
        method:'get',
        url:'/sys/alert_info'
    })
}

// 获取未读消息
export function getUnreadMessages(order_id){
   return request({
       method:'get',
       url:'/message/unread',
       params:{
        order_id
       }
   })
}
