import request from '@/utils/request';

export default {
  configs(coin) {
    //配置参数
    return new Promise((resolve, reject) => {
      const url = '/sys/configs';
      request.get(url,{
        params:{
          coin
        }
      }).then(response => {

        resolve(response.data);
      }).catch(error => {

        reject(error.response.data);
      });
    });
  },
  countries() {
    //获取国家列表
    return new Promise((resolve, reject) => {
      const url = "/sys/countries"
      request.get(url)
        .then((res) => {
          resolve(res.data)
        }).catch((error)=>{
          reject(error)
      })
    })
  },
  currencies() {
    //获得货币列表
    return new Promise((resolve, reject) => {
      const url = "/sys/currencies"
      request.get(url)
        .then((res) => {
          resolve(res.data)
        }).catch((error)=>{
          reject(error)
      })
    })
  },
  currencies_simple() {
    //新的获得货币列表
    return new Promise((resolve, reject) => {
      const url = "/sys/currencies_simple"
      request.get(url)
        .then((res) => {
          resolve(res.data)
        }).catch((error)=>{
          reject(error)
      })
    })
  },
  exchangerate(name) {
    //获取法币汇率
    return new Promise((resolve, reject) => {
      const url = "/sys/exchange-rate";
      request.get(url, {
        params: {
          name  //根据传递进来的数据显示
        }
      }).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  onMarketPrice(coin) {
    //获取币种市场价
    return new Promise((resolve) => {
      const url = "/sys/market-price";
      request.get(url, {
        params: {
          coin  //根据传递进来的数据显示
        }
      }).then((res) => {
        resolve(res.data)
      }).catch((err) => {

        console.log(err)
      })
    })
  },
  info(id) {
    //取得用户信息
    return new Promise((resolve) => {
      const url = '/user/info';
      request.get(url, {
        params: {
          id
        }
      }).then((res) => {

        resolve(res.data)
      })
    })
  },
  csrfApi() {//获取令牌
    return new Promise((resolve) => {
      const url = '/sys/csrf-token';
      request.get(url)
        .then((res) => {
          resolve(res.data)
        })
    })
  },
}
