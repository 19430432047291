<template>
  <div id="app" :style="{height:isAccountPage?'100%':'auto'}">
    <router-view style="padding-top: var(--headerHeight)"/>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'App',
  data() {
    return {
      informationPush: true
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    }),
    isAccountPage() {
      let routeName = this.$route.name
      return routeName == 'login' || routeName == 'forget'
    }
  },
  created() {
    this.initCounterConfig()
    this.getQueryString('token')
  },
  watch: {
    $route() {
      this.onRouter()
    }
  },
  methods: {
    ...mapMutations('global', ['initCounterConfig']),
    getQueryString(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) {
        localStorage.setItem('authorizationToken', r[2])
      }
    },
    onRouter() {
      // 监听路由
      if (this.$route.name == 'order-details') {
        this.informationPush = false
      } else {
        this.informationPush = true
      }
    }
  }
}
</script>

<style lang="less">
#app {
  position: relative;
  background: #fff;
  min-width: 1200px;
  // background: #1d2136;
  // min-height: 680px;
}
swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: linear;
  -moz-transition-timing-function: linear;
  -ms-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
  margin: 0 auto;
}
input,
button,
select,
textarea {
  outline: none !important;
  // border: 0 !important;
  box-sizing: border-box !important;
  appearance: none !important;
  -moz-appearance: none !important;
  -ms-appearance: none !important;
  -o-appearance: none !important;
  -webkit-appearance: none !important;
  resize: none !important;
  background-color: #fff;
}
button {
  outline: none !important;
  // border: 0 !important;
}
.fs12 {
  font-size: 12px !important;
}
.mt11 {
  margin-top: 11px !important;
}
.mr5 {
  margin-right: 5px !important;
}
.ml5 {
  margin-left: 5px !important;
}
.ml10 {
  margin-left: 10px !important;
}
.ml15 {
  margin-left: 15px !important;
}
.vat {
  vertical-align: top;
}
.vatt {
  vertical-align: text-top;
}
.pd0 {
  padding: 0 !important;
}
.mlr160 {
  margin: 0 160px;
}
.clc69c6d {
  color: #395aff;
}
.outline {
  outline: none;
}
.ivu-btn-text:active {
  box-shadow: none;
}
.red {
  color: #ee5745;
}
/* 公共 */
.header .ivu-menu-submenu:hover,
.header-top-right .ivu-menu-submenu:hover {
  border: none !important;
}
.ivu-menu-item:hover,
.ivu-menu-light.ivu-menu-horizontal .ivu-menu-item-active {
  border-bottom: 2px solid #c69c6d !important;
}
.header .ivu-select-dropdown,
.header-top .ivu-select-dropdown {
  background: #fff !important;
}
.header .ivu-select-dropdown .ivu-menu-item:hover,
.header-top .ivu-select-dropdown .ivu-menu-item:hover {
  background: #fff !important;
  border: none !important;
}
.header-top .ivu-select-dropdown {
  margin: 0 !important;
  border: none !important;
}
.header-top .ivu-menu-item:hover,
.header-top .ivu-menu-light.ivu-menu-horizontal .ivu-menu-item-active {
  border: none !important;
}
.header-top .ivu-menu-light.ivu-menu-horizontal .ivu-menu-item,
.header-top .ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu {
  color: #ccc !important;
  border-bottom: none !important;
}
.ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown .ivu-menu-item-selected,
.ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown .ivu-menu-item-selected:hover {
  background: none !important;
}
.login .card {
  border: none;
}
.login input {
  border: 1px solid #c69c6d !important;
  box-shadow: none;
}
.tips .ivu-poptip-inner {
  background: #303133 !important;
}
.tips .ivu-poptip-popper[x-placement^='bottom'] .ivu-poptip-arrow:after {
  border-bottom-color: #303133 !important;
}
.tips .ivu-poptip-popper[x-placement^='bottom'] .ivu-poptip-arrow {
  border: none;
}
.tips .ivu-poptip-title-inner {
  color: #ccc !important;
}
.tips .ivu-poptip-title:after {
  display: none;
}
.tips .ivu-poptip-body {
  display: none;
}
.header .ivu-menu-submenu-title > i,
.header .ivu-menu-submenu-title span > i {
  margin-right: 0;
}
.login-wrap {
  min-height: 520px;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
@media screen and (max-width: 1199px) {
  .container {
    width: 96%;
  }
}
@media screen and (max-width: 1099px) {
  .header .ivu-menu-horizontal .ivu-menu-item,
  .ivu-menu-horizontal .ivu-menu-submenu {
    padding: 0 5px;
  }
  .container {
    width: 96%;
  }
  .xian1::before {
    left: -9px !important;
  }
}
</style>
