import axios from 'axios';
import store from '@/store/index.js'
import il8n from '@/lang/il8n.js'
import {
  Modal
} from 'view-ui-plus'
function getQueryString(name) {
  // 获取参数
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) {
    localStorage.setItem('authorizationToken', r[2])
    return r[2]
  }
}

// 错误提示
function errorHints(title, error) {
  if (typeof error.response.data == 'string') {
    Modal.error({
      title,
      content: error.response.data
    });
  } else {
    if (error.response.data.errors) {
      let errors = error.response.data.errors
      let messages = []
      for (let filed in errors) {
        messages.push(errors[filed][0])
      }
      Modal.error({
        title,
        content: messages[0]
      });
    } else {
      if (error.response.data.message) {
        Modal.error({
          title,
          content: error.response.data.message
        });
      }
    }
  }
}

let apiAddress = process.env.VUE_APP_API_BASE + '/pc';
//有通用配置的axios实例;axios#get(url[, config]);axios#post(url[, data[, config]])
const request = axios.create({
  baseURL: apiAddress,
  timeout: 30000,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
  },
});

// request拦截器
request.interceptors.request.use(config => {
  let authorizationToken = localStorage.getItem('authorizationToken')
  const language=window.localStorage.getItem('language')
  if (config.method === 'post') {
    config.data = {
      ...config.data,
      ua: 'h5',
      ...(!(config.data && config.data['language']) && {
        language
      })
    }
  } else if (config.method === 'get') {
    config.params = {
      ...config.params,
      ua: 'h5'
    }
  }
  if (!(config.params && config.params['language']) && config.url.indexOf('language=') < 0) {
    config.params = {
      ...config.params,
        language
      }
  }
  if (authorizationToken) {
    config.headers['Authorization'] = `Bearer ${authorizationToken}`
  } else {
    let au = getQueryString('token');
    if (au != null) {
      config.headers['Authorization'] = `Bearer ${au}`
    }
  }
  return config
}, error => {
  return Promise.reject(error);
});

// response拦截器
request.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response) {
    const title = il8n.t('Tips')
    switch (error.response.status) {
      case 401:
        store.commit('user/removeLoginInfo')
        Modal.error({
          title,
          content: '请登录后操作',
          onOk: () => {
            store.dispatch('user/getOtoken').then(() => {
              store.commit('global/setShowLoginPopup', {value: true, type: null})
            })
          }
        });
        break;
      case 419:
        Modal.error({
          title,
          content: '登录失效，请重新登录',
          onOk: () => {
            store.dispatch('user/getOtoken').then(() => {
              store.commit('global/setShowLoginPopup', {value: true, type: null})
            })
          }
        });
        break;
      default:
        errorHints(title, error)
    }
  }
  return Promise.reject(error);
})

export default request;
