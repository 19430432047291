const state = {
  type: 'OnlineSell',
  coin: 'BTC',
  currency_code: 'CNY'
}

const mutations = {
  // 设置交易类型
  setTradeType(state, type) {
    state.type = type
  },
  // 设置交易币种
  setTradeCoin(state, coin) {
    state.coin = coin
  },
  // 设置交易货币
  setTradeCurrency(state, currency_code) {
    state.currency_code = currency_code
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
