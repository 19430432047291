import echo from '@/utils/echo.js'
import {
  getCurrencyMarkePrice,
  getCurrencyExchangeRate
} from '@/api/ad.js'

const state = {
  tradeCoin: 'ETH',
  quoteCoin: 'BTC',
  opened_to_public: 1,// 0为倒计时
  resolution: 5,
  latestPrice: '',
  tradePrice: '',
  isUpdateLatestPrice: false,
  marketPrice: '',
  rate: '',
  zoom: true,
  symbolQuote: ''
}

const mutations = {
  // k线放大缩小
  setZoom(state, zoomdata) {
    state.zoom = zoomdata
  },
  // 设置交易币种
  setTradeCoin(state, tradeCoin) {
    state.tradeCoin = tradeCoin
    localStorage.setItem('TRADE_COIN', tradeCoin)
  },
  // 设置k线时间线
  setResolution(state, resolution) {
    state.resolution = resolution
    localStorage.setItem('RESOLUTION', resolution)
  },
  // 获取是手续费
  setSymbolQuote(state, data) {
    state.symbolQuote = data;
  },
  // 设置报价币种
  setQuoteCoin(state, quoteCoin) {
    state.quoteCoin = quoteCoin
    localStorage.setItem('QUOTE_COIN', quoteCoin)
  },
  // 设置倒计时
  setOpenedToPublic(state, opened_to_public) {
    state.opened_to_public = opened_to_public
  },
  // 设置最新价
  setLatestPrice(state, latestPrice) {
    state.latestPrice = latestPrice
  },
  // 设置交易价
  setTradePrice(state, tradePrice) {
    state.tradePrice = tradePrice
  },
  // 初始化币种
  initCoins(state) {
    let tradeCoin = localStorage.getItem('TRADE_COIN')
    let quoteCoin = localStorage.getItem('QUOTE_COIN')
    if (tradeCoin) {
      state.tradeCoin = tradeCoin
    }
    if (quoteCoin) {
      state.quoteCoin = quoteCoin
    }
  },
  // 设置是否更新最新价
  setisUpdateLatestPrice(state, isUpdateLatestPrice) {
    state.isUpdateLatestPrice = isUpdateLatestPrice
  },
  // 设置市场价
  setMarketPrice(state, marketPrice) {
    state.marketPrice = marketPrice
  },
  // 设置换算比例
  setRate(state, rate) {
    state.rate = rate
  }
}

const actions = {
  // 离开socket监听
  leaveSocketListen({
    state,
    rootState
  }) {
    return new Promise((resolve, reject) => {
      let quoteCoin = state.quoteCoin
      let tradeCoin = state.tradeCoin
      let userInfo = rootState.user.userInfo
      let channels = [`SymbolPrice:${quoteCoin}`, `Exchange:${tradeCoin}/${quoteCoin}.${userInfo.id}`, `KLine:${tradeCoin}/${quoteCoin}|1`, `ExecExchange:${tradeCoin}/${quoteCoin}`, `OrderBook:${tradeCoin}/${quoteCoin}`]
      if (echo.isAval()) {
        channels.forEach(channelName => {
          echo.instance().leave(channelName)
        })
        resolve(channels)
      } else {
        reject()
      }
    })
  },
  // 获取市场价
  getMarketPrice({ state, commit }) {
    getCurrencyMarkePrice(state.quoteCoin).then(res => {
      commit('setMarketPrice', res.data.data)
    })
  },
  getRate({ commit }) {
    getCurrencyExchangeRate('CNY').then(res => {
      commit('setRate', res.data.data)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
