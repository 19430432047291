import router from './router'
import store from './store'

function requiresAuth(to, next) {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // next({
    //   path: '/login',
    //   query: {
    //     redirect: to.fullPath
    //   }
    // })
    store.commit('global/setShowLoginPopup', { value: true, type: 1 })
  } else {
    next()
  }
}
router.beforeEach((to, from, next) => {
  // if (to.name == 'register' || to.name == 'forget' || to.name == 'login'){
  //   next();
  //   return false;
  // }
  let isLogin = store.state.user.isLogin
  if (isLogin) {
    next()
  } else {
    // if (isLogin === null) {
    //   store
    //     .dispatch('user/getLoginStatus')
    //     .then(isLogin => {
    //       if (isLogin) {
    //         next()
    //       } else {
    //         requiresAuth(to, next)
    //       }
    //     })
    //     .catch(() => {
    //       requiresAuth(to, next)
    //     })
    // } else {
    //   requiresAuth(to, next)
    // }
    requiresAuth(to, next)
  }
})

// 获取usdt转usd汇率
// router.beforeEach((to, from, next) => {
//   const usdtUsdRate = store.state.global.usdtUsdRate
//   if (usdtUsdRate !== null) {
//     next()
//   } else {
//     store
//       .dispatch('global/getUsdtUsdRate')
//       .then(() => {
//         next()
//       })
//       .catch(() => {
//         next()
//       })
//   }
// })
