/* eslint-disable no-new */
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { createApp } from 'vue'
import App from './App'
import router from './router'
import axios from 'axios'
import i18n from './lang/il8n.js'
import './permission.js'
import store from './store'
import * as socketApi from '@/utils/socket'

import '@/assets/less/app.less'
import '@/assets/style.css'
import '@/assets/css/page.css'
import scroll from 'vue-seamless-scroll'
import ViewUIPlus from 'view-ui-plus';

const app = createApp(App)

app.use(store)
app.use(router)
app.use(i18n)
app.use(ViewUIPlus, {
  i18n
});
app.use(scroll)

import vueToTop from 'vue-totop'
app.use(vueToTop)
import utils from '@/utils/utils'
app.use(utils)
app.config.globalProperties.$t = i18n;
app.config.globalProperties.socketApi = socketApi
app.config.productionTip = false
app.config.globalProperties.$axios = axios
import VueAxios from 'vue-axios'
app.use(VueAxios, axios)
app.mount("#app")