import Bus from '@/utils/eventBus.js'

var websock = null;
var globalCallback = null;// socket参数
//var globalagentData = null;//socket回调函数
var tt = null;//socket回调函数
var lockReconnect = false;//避免重复连接
var loading = false;//重新连接请求接口

//心跳检测;有的时候，客户端发送3次心跳包服务端均未回复才判定为失去连接，所以这时需要加num上计数来判断。
var heartCheck = {
  timeout: 7000,//7s
  timeoutObj: null,
  reset: function(){
      clearTimeout(this.timeoutObj);
      this.start();
  },
  start: function(){
      this.timeoutObj = setTimeout(function(){
        websock.send('{"msgType":6}')
      }, this.timeout)
  }
};
 // 初始化weosocket
function initWebSocket () {
// ws地址 -->这里是你的请求路径.var ws= 'ws://www.baidu.com/ws'
// console.log(process.env.VUE_APP_ECHO_BASE)
  var ws= process.env.VUE_APP_ECHO_BASEjava
  websock = new WebSocket(ws)
  //接收；关闭；成功；错误
  websock.onmessage = function (e) {
    websocketonmessage(e);
  }
  websock.onclose = function (e) {
    websocketclose(e);
  }
  websock.onopen = function () {
    websocketOpen();
  }
  websock.onerror = function () {
    websocketErr();
  }
}

// 实际调用的方法
function sendSock (agentData, callback) {
  //globalagentData = agentData;
  globalCallback = callback;
  if (websock.readyState === websock.OPEN) {
    // 若是ws开启状态
    websocketsend(agentData)
  } else {
    // 若未开启 ，则等待1s后重新调用
    setTimeout(function () {
      sendSock(agentData, callback)
    }, 1000)
  }
}
export function Queue(size) {
  var list = [];
  //向队列中添加数据
  this.push = function(data) {
      if (data==null) {
          return false;
      }
      //如果传递了size参数就设置了队列的大小
      if (size != null && !isNaN(size)) {
          if (list.length == size) {
              this.pop();
          }
      }
      list.unshift(data);
      return true;
  }
  //从队列中取出数据
  this.pop = function() {
      return list.pop();
  }
  //返回队列的大小
  this.size = function() {
      return list.length;
  }
  //返回队列的内容
  this.quere = function() {
      return list;
  }
}
// 数据接收
function websocketonmessage (e) {
    
  // console.log(JSON.parse(e.data));
  // 缓存队列;var queue = new Queue();
  // var queue = new Queue();
  // console.log(e)
  // console.log(!e.data.msgType)
  // if(!e.data.msgType){
  //   clearTimeout(queueTime);
  // }
  // var queueTime = setTimeout(function(){
  //     console.log(e)
  //     queue.push(e);
  // },1000);
  
  // console.log(queue.pop());

  heartCheck.reset(); // 心跳
  if (typeof globalCallback === "function"){
    globalCallback(JSON.parse(e.data));
  }
}

// 数据发送
function websocketsend (agentData) {
  websock.send(JSON.stringify(agentData))
}

// 关闭
function websocketclose () {
  // console.log('WebSocket连接关闭')
  reconnect();
}

// 连接错误
function websocketErr () {
  // console.log('WebSocket连接错误')
  reconnect();
}

// 创建 websocket 连接
function websocketOpen () {
  // console.log('WebSocket连接成功')
  if(loading){
    // console.log("重新请求接口")
    Bus.$emit('recData',loading)
  }
  heartCheck.start();// 发送心跳
}

// 重连
function reconnect() {
  if(lockReconnect) {return}
  lockReconnect = true;
  //没连接上会一直重连，设置延迟避免请求过多
  tt && clearTimeout(tt);
  tt = setTimeout(function () {
    // console.log("重新连接")
    initWebSocket();
    loading = true;
    lockReconnect = false;
  }, 2000);
}

initWebSocket()

// 将方法暴露出去
export {
  sendSock
}