import request from '@/utils/request';
export default {
  onRegister(username, country_code, mobile, password, smsverify) {
    //注册
    return new Promise((resolve, reject) => {
      const url = "/register";
      request.post(url, {
          username,
          country_code,
          mobile,
          password,
          smsverify
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data); //错误返回提示信息
        })
    })
  },
  //获取马甲列表
  shoadows() {
    return new Promise((resolve, reject) => {
      const url = '/shadows';
      request.post(url)
        .then((res) => {
          resolve(res.data)
        }).catch((err) => {
          reject(err.response.data);
        })
    })
  },
  //直接登录马甲账号
  shoadowsLogin(id) {
    return new Promise((resolve, reject) => {
      const url = '/shadow/login';
      request.post(url, {
          id
        })
        .then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err.response)
        })
    })
  },
  //获取当前账户信息
  profile() {
    return new Promise((resolve, reject) => {
      const url = "/profile";
      request.get(url)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data); //错误返回提示信息
        })
    })
  },
  //退出登录
  logout() {
    return new Promise((resolve, reject) => {
      const url = '/logout';
      request.post(url).then((res) => {
        resolve(res)
      }).catch(() => {
        reject()
      })
    })
  },
}

// 获取用户简介
export function getProfile(id) {
  return request({
    method: 'get',
    url: '/user/info',
    params: {
      id
    }
  })
}

// 获取用户屏蔽状态
export function getUserShieldStatus(user_id) {
  return request({
    method: 'post',
    url: '/ignore/judge',
    data: {
      user_id
    }
  })
}

// 获取用户信任状态
export function getUserTrustStatus(user_id) {
  return request({
    method: 'post',
    url: '/trust/judge',
    data: {
      user_id
    }
  })
}

// 添加信任
export function addTrust(user_id) {
  return request({
    method: 'post',
    url: '/trust/add',
    data: {
      user_id
    }
  })
}

// 移除信任
export function removeTrust(user_id) {
  return request({
    method: 'post',
    url: '/trust/remove',
    data: {
      user_id
    }
  })
}

// 添加屏蔽
export function addShield(user_id) {
  return request({
    method: 'post',
    url: '/ignore/add',
    data: {
      user_id
    }
  })
}

// 移除屏蔽
export function removeShield(user_id) {
  return request({
    method: 'post',
    url: '/ignore/remove',
    data: {
      user_id
    }
  })
}

// 获取交易数据
export function getTradeData(userid, type,page){
  return request({
    method:'get',
    url:`/user/${userid}`,
    params:{
      type,
      page
    }
  })
}
